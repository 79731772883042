import React, { useEffect } from 'react';
import useCnaeContext from '../../contexts/CnaeContext';
import useFetchCnae from '../../customHooks/UseFetchCnae';
import { useQueryParam, StringParam } from 'use-query-params';
import SEO from '../atoms/SEO';
import WrapperInfoCnae from '../templates/WrapperInfoCnae';

export default function Search() {
  const [queryParam, _] = useQueryParam('query', StringParam);
  const { fetch } = useFetchCnae();
  const { submitInput, setCurrentCnae } = useCnaeContext();

  useEffect(() => {
    searchForQueryString();
    setCurrentCnae(false);
  }, [queryParam]);

  async function searchForQueryString() {
    const result = await fetch({
      search: queryParam,
    });
    submitInput(result, queryParam);
  }

  return (
    <>
      <SEO />
      <WrapperInfoCnae />
    </>
  );
}
