import React from 'react';
import Search from '../components/pages/Search';
import { Box } from '@material-ui/core';

export default function search() {
  return (
    <>
      <Box>
        <Search />
      </Box>
    </>
  );
}
